import axios from 'axios';
import { parseName } from 'utils/parsename';
import Cookies from 'js-cookie';

interface ContactFormData {
    email: string;
    name: string;
    contact_purpose: 'pricing' | 'demo request' | 'feedback' | 'other';
    message: string;
}

interface TeacherTrailEndB2BData {
    email: string;
    name: string;
    which_plan_are_you_interested_in_: '$1,000 - Up to 100 Students' | '$3,500 - Up to 500 Students' | '$5,000 - Up to 1,200 Students' | 'other';
    message: string;
}

interface DemoFormData {
    email: string;
    name: string;
    message?: string;
}

interface surveyData {
    email: string;
    option: string;
    message?: string;
}

interface EmailCapturePopupData {
    email: string;
    name: string;
    organization?: string;
    conference?: string;
}

interface SummerSignupData {
    email: string;
    firstname: string;
    muz_account_school_name?: string;
    jobtitle?: string;

}

export const submitHubspotForm = (portalId: string, formGuid: string, formData: any, pageName: string) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const fields = [];
    for (const [key, value] of Object.entries(formData)) {
        console.log(`${key}: ${value}`);
        fields.push({
            name: key, value
        });
    }

    const context = {
        hutk: null, pageName: null, pageUri: null
    };

    // save the hubspot tracking cookie
    const hutk = Cookies.get('hubspotutk');
    if (hutk) {
        context.hutk = hutk;
    }

    // save the page name
    if (pageName) context.pageName = pageName;

    // save the url
    context.pageUri = window.location.href;

    // post the form
    return axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, { portalId, formGuid, fields, context }, config);
};

export const submitContactForm = async (formData: ContactFormData, pageName = 'contact us') => {
    // eslint-disable-next-line camelcase
    const { email, name, contact_purpose, message } = formData;
    const { firstName, lastName } = parseName(name, []);
    if (!name || !email) {
        console.error('contact form error: Please enter your name and email.');
        return null;
    }

    // post contact form to hubspot
    const portalId = '8464088';
    const formGuid = 'bd13ec8a-b2e5-46e1-abc9-5b9adfd956c6';
    const response = await submitHubspotForm(portalId, formGuid, {
        firstname: firstName, lastname: lastName === '' ? firstName : lastName, email, message, // eslint-disable-next-line camelcase
        contact_purpose
    }, pageName);

    console.log(response);
    return response;
};

export const submitTeacherTrailEndForm = async (formData: TeacherTrailEndB2BData, pageName = 'contact us') => {
    // eslint-disable-next-line camelcase
    const { email, name, which_plan_are_you_interested_in_, message } = formData;
    const { firstName, lastName } = parseName(name, []);
    if (!name || !email) {
        console.error('contact form error: Please enter your name and email.');
        return null;
    }
    // post contact form to hubspot
    const portalId = '8464088';
    const formGuid = 'fc45b250-d440-4b95-9b12-1d8dbae2bec2';
    const response = await submitHubspotForm(portalId, formGuid, {
        firstname: firstName, lastname: lastName === '' ? firstName : lastName, email, message, // eslint-disable-next-line camelcase
        which_plan_are_you_interested_in_
    }, pageName);

    console.log(response);
    return response;
};

export const submitEndTrialSurvey = async (formData: surveyData, pageName = 'trial end survey') => {
    const { email, option, message } = formData;
    // const { firstName, lastName } = parseName(name, []);
    if (!email) {
        console.error('contact form error: Please enter your name and email.');
        return null;
    }

    // post contact form to hubspot
    const portalId = '8464088';
    const formGuid = 'f6774e6e-260e-465f-83ab-8eca31b378a1';
    const response = await submitHubspotForm(portalId, formGuid, {
        // firstname: firstName,
        // lastname: lastName === '' ? firstName : lastName,
        option, email, message
    }, pageName);

    console.log(response);
    return response;
};

export const submitPopupEmailCapture = async (formData: EmailCapturePopupData, pageName = 'modal email capture') => {
    const { email, name, organization, conference } = formData;
    // const { firstName, lastName } = parseName(name, []);
    if (!email) {
        console.error('contact form error: Please enter your name and email.');
        return null;
    }

    // post contact form to hubspot
    const portalId = '8464088';
    const formGuid = '4da6d7ca-2dee-424d-836e-595251b14cb6';
    const response = await submitHubspotForm(portalId, formGuid, {
        // firstname: firstName,
        // lastname: lastName === '' ? firstName : lastName,
        email, name, organization, // conference
    }, pageName);

    // post to the server to trigger an email
    axios.post('https://app-server-staging.muzology.com/api/conference/email_signup/', { email, name, organization, source: conference })
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.error(err);
        });

    console.log(response);
    return response;
};

export const submitTeacherInfoCapture = async (formData: SummerSignupData, pageName = 'modal email capture') => {
    const { email, firstname, muz_account_school_name, jobtitle } = formData;
    // const { firstName, lastName } = parseName(name, []);
    if (!email) {
        console.error('contact form error: Please enter your name and email.');
        return null;
    }

    // post contact form to hubspot
    const portalId = '8464088';
    const formGuid = '0b721276-0fb0-4096-a52b-0fa344473626';
    const response = await submitHubspotForm(
        portalId,
        formGuid,
        {
            firstname,
            jobtitle,
            email,
            muz_account_school_name
        },
        pageName
    );

    console.log(response);
    return response;
};

export const submitScheduleDemoForm = async (formData: DemoFormData, pageName = 'demo signup') => {
    const { email, name, message } = formData;
    if (!name || !email) {
        return false;
    }
    const { firstName, lastName } = parseName(name, []);

    const portalId = '8464088';
    const formGuid = '73cfe627-5e3d-415a-ad83-49771db5ac97';
    try {
        const response = await submitHubspotForm(portalId, formGuid, {
            firstname: firstName, lastname: lastName, email, comments: message
        }, pageName);
        console.log(response);
        return response;
    } catch (e) {
        console.error('Error posting to hubspot: ', e);
        throw e;
    }

    /*
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields: [
        {
          name: 'firstname',
          value: firstName,
        },
        {
          name: 'lastname',
          value: lastName,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'comments',
          value: message,
        },
        // {
        //   name: 'state',
        //   value: state,
        // },
        // {
        //   name: 'school',
        //   value: school,
        // },
        // {
        //   name: 'howhear',
        //   value: howHear,
        // },
        // {
        //   name: 'favoritesong',
        //   value: favoriteSong,
        // },
      ],
    },
    config
  );
  console.log(response);
  return response;
   */
};

export const submitPilotSignupForm = async (formData: DemoFormData, pageName = 'pilot signup') => {
    const { email, name, message } = formData;
    if (!name || !email) {
        return false;
    }
    const { firstName, lastName } = parseName(name, []);

    const portalId = '8464088';
    const formGuid = 'bd873451-59a3-4021-82a7-38ea536c0bf2';
    try {
        const response = await submitHubspotForm(portalId, formGuid, {
            firstname: firstName, lastname: lastName, email, comments: message
        }, pageName);
        console.log(response);
        return response;
    } catch (e) {
        console.error('Error posting to hubspot: ', e);
        throw e;
    }
};

/*
export const submit_teacher_contact_form = async (formData: any) => {
  // user_type: 'teacher',
  // name: '',
  // email: '',
  // state: null,
  // school: null,
  // howHear: '',
  // favoriteSong: '',
  const { email, name, state, school, howHear, favoriteSong } = formData;
  const parsedName = parseName(name, []);
  const { firstName, lastName } = parsedName;
  if (!name || !email) {
    alert('Please enter your name and email.');
  } else {
    const portalId = '8464088';
    const formGuid = '73cfe627-5e3d-415a-ad83-49771db5ac97'; // this is probably wrong formGuid
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'state',
            value: state,
          },
          {
            name: 'school',
            value: school,
          },
          {
            name: 'howhear',
            value: howHear,
          },
          {
            name: 'favoritesong',
            value: favoriteSong,
          },
        ],
      },
      config
    );
    console.log(response);
    return response;
  }
};
*/
